import * as React from "react"
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import ReactModal from 'react-modal';
import DemoRequestModal from "./demo-request-modal";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import * as contentImageStyles from "./landing-content-image-alpha.module.css";

export const query = graphql`
  fragment LandingContentimageContent on FiveoakCMS_ComponentLandingContentimage {
    __typename
    descriptor
    title
    body
    direction
    image {
      url
      publicUrl
      provider
      alternativeText
      ext
      imageFile {
        childImageSharp {
          gatsbyImageData(
            width: 700
          )
        }
      }
    }
    backgroundimage {
      url
      publicUrl
      provider
      alternativeText
      ext
      imageFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    imagelist {
      text
      image {
        url
        publicUrl
        provider
        alternativeText
        ext
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

// markup
const LandingContentImageAlpha = ({ content }) => {
  const [demoModalOpen, setDemoModalOpen] = React.useState(false)
  const image = getImage(content.image?.imageFile);
  let backgroundImage;
  if (content.backgroundimage) {
    backgroundImage = content.backgroundimage.publicUrl;
  }
  return (
    <div className="relative z-0">
      {backgroundImage && <div alt={content.backgroundimage.alternativeText || ''}
        className="bg-cover absolute left-0 w-full top-0 h-full"
        style={{ backgroundImage: `linear-gradient(rgb(238, 238, 238, 0.8), rgb(238, 238, 238, 0.8)), url(${backgroundImage})` }}
      />}
      <section className="relative z-10 text-primary-500 text-lg py-12 px-5 flex flex-col lg:flex-row items-center max-w-screen-xl mx-auto">
        <div className={'order-2 lg:pl-10 lg:w-2/5 ' + (content.direction === 'image_on_right' ? 'lg:order-1' : '')}>
          {content.descriptor && <div className="text-blue-300 text-center lg:text-left text-sm uppercase font-bold">{content.descriptor}</div>}
          {content.title && <div className="text-center font-black lg:text-left text-3xl whitespace-pre-wrap mb-7">
            <ReactMarkdown className={contentImageStyles.title}>{content.title}</ReactMarkdown>
          </div>}
          {content.body && <div className="mb-8 font-semibold font-sans">{content.body}</div>}
          {
            <div>
              <button type="button"
                onClick={() => setDemoModalOpen(true)}
                className="inline-block ml-2 cursor-pointer bg-blue-300 hover:bg-blue-200 text-white font-bold rounded px-2 sm:px-6 py-1 sm:py-2">
                Start free trial*
              </button>
              <button type="button"
                onClick={() => setDemoModalOpen(true)}
                className="inline-block ml-2 cursor-pointer bg-marble-200 text-black hover:bg-marble-300 text-white font-bold rounded px-2 sm:px-6 py-1 sm:py-2">
                Book a Demo
              </button>
            </div>
          }
          {content.imagelist && (content.imagelist.length || '') && <ul>
            {content.imagelist.map((item, i) => {
              const bulletImage = getImage(item.image.imageFile);
              return (<li key={i} className="flex flex-row items-center mb-5">
                {bulletImage &&
                  <GatsbyImage image={bulletImage}
                    alt={item.image.alternativeText || ''}
                    className="mr-2" />
                }
                {!bulletImage &&
                  <img src={item.image.publicUrl}
                    alt={item.image.alternativeText || ''}
                    className="mr-2" loading="lazy" />
                }
                <ReactMarkdown className="text-primary-300">{item.text}</ReactMarkdown>
              </li>);
            })}
          </ul>}
        </div>
        <div className={'order-1 lg:pl-10 lg:w-3/5 mx-auto mb-8 lg:mb-0' + (content.direction === 'image_on_right' ? 'lg:order-2' : '')}>
          {image && <GatsbyImage image={image} alt={content.image.alternativeText} />}
        </div>
      </section>
      <ReactModal isOpen={demoModalOpen}
        contentLabel="Request a Demo"
        overlayClassName="demo-modal-overlay"
        className="demo-modal"
        onRequestClose={() => setDemoModalOpen(false)}>
        <DemoRequestModal onRequestClose={() => setDemoModalOpen(false)}></DemoRequestModal>
      </ReactModal>
    </div>
  )
}

export default LandingContentImageAlpha
