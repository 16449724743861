import * as React from "react";
import ReactMarkdown from "react-markdown";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";

export const query = graphql`
  fragment LandingLogosContent on FiveoakCMS_ComponentLandingLogos {
    __typename
    toptext
    bottomtext
    images {
      url
      publicUrl
      provider
      alternativeText
      ext
      imageFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

// markup
const LandingLogos = ({ logos }) => {
  return (
    <section className={`text-primary-500 py-12 px-5 flex flex-col items-center`}>
      {logos.toptext && <div className="mb-6 text-3xl text-center">
        <ReactMarkdown>{logos.toptext}</ReactMarkdown>
      </div>}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:flex lg:flex-row gap-x-7 gap-y-2.5 items-center justify-center">
        {logos.images.map((pic, i) => {
          const image = getImage(pic.imageFile);
          if (!image && pic) {
            return (<div className="text-center" key={i}>
              <img src={pic.publicUrl}
                className="inline-block"
                alt={pic.alternativeText || ''}
                loading="lazy" />
            </div>)
          }
          return (<div className="text-center" key={i}>
            <GatsbyImage image={image} alt={pic.alternativeText || ''} />
          </div>);
        })}
      </div>
      {logos.bottomtext && <div className="mt-1 text-2xl text-center">
        <ReactMarkdown>{logos.bottomtext}</ReactMarkdown>
      </div>}
    </section>
  )
}

export default LandingLogos
