import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import * as landingCarouselfeatureStyles from './landing-carousel-feature.module.css'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactModal from 'react-modal';
import DemoRequestModal from "./demo-request-modal";
import { graphql } from 'gatsby';
import FiveStars from '../images/fivestars.svg'
import { CSSTransition } from 'react-transition-group';

export const query = graphql`
  fragment LandingCarouselContent on FiveoakCMS_ComponentLandingCarousel {
    __typename
    title
    body
    carouselslide {
      heading
      title
      description
      image {
        url
        provider
        alternativeText
        ext
        imageFile {
          childImageSharp {
            gatsbyImageData(width: 766)
          }
        }
      }
    }
  }
`;

// markup
const LandingCarouselfeature = ({ carousel }) => {
  const [demoModalOpen, setDemoModalOpen] = React.useState(false)
  const [isEnter, setIsEnter] = React.useState(true)
  const [currentIdx, setCurrentIdx] = React.useState(0)
  const currentCarousel = carousel.carouselslide[currentIdx]
  const image = getImage(currentCarousel.image.imageFile)
  React.useEffect(() => {
    const slide = setInterval(() => { setIsEnter(false); setTimeout(() => { setCurrentIdx(prev => ((prev + 1) % carousel.carouselslide.length)); setIsEnter(true) }, 500) }, 4500)
    return () => clearInterval(slide)
  })
  return (
    <section
      id='features'
      className={`lg:pb-6 pb-1 pt-8 px-5`}
    >
      <div className="text-center flex flex-col justify-center align-center pb-14">
        <h1 className="flex justify-center leading-tight md:leading-tight md:mb-2 mb-1">
          <img src={FiveStars} alt='five_stars' />
        </h1>
        <div className="max-w-full text-center mx-auto text-4xl font-black">
          Why our business partners love fiveoak
        </div>
        <div className='bg-purple-300 mt-1 h-14 sm:h-20 rounded-2xl sm:rounded-3xl flex justify-around items-center'>
          {carousel.carouselslide.map((data, i) => (<div key={i} onClick={() => setCurrentIdx(i)} className={`${i === currentIdx ? 'bg-marble-200' : ''} cursor-pointer h-10 sm:h-8 flex p-1 sm:p-6 flex-column items-center scale-75 sm:scale-100 text-xs sm:text-base leading-3 rounded-2xl tracking-lighter sm:tracking-normal font-light sm:font-black`}>{data?.heading}</div>))}
        </div>
      </div>
      {carousel.carouselslide.map((data, i) => (<CSSTransition key={i} className={`slideo flex lg:flex-row lg:items-stretch lg:justify-center flex-col ${i !== currentIdx ? 'hidden' : ''}`} in={isEnter} timeout={1000}>
        <div className={`flex lg:flex-row lg:items-stretch lg:justify-center flex-col`}>
          <div className="flex flex-col items-center lg:justify-center lg:mb-0 mb-20">
            <GatsbyImage
              image={image}
              alt={data.image.alternativeText || ''}
              className={`rounded-3xl`}
              loading="eager"
            />
          </div>
          <div className="shrink-0 lg:ml-1 lg:w-96 w-full lg:max-w-full max-w-xl lg:mr-0 mx-auto bg-marble-200  lg:py-1 py-4 px-5 flex flex-col justify-center">
            <div className="max-w-lg mx-auto font-black text-2xl">
              <ReactMarkdown>{data.title}</ReactMarkdown>
            </div>
            <div className="max-w-lg mx-auto mt-2">
              <ReactMarkdown>{data.description}</ReactMarkdown>
            </div>
            <button type="button"
              onClick={() => setDemoModalOpen(true)}
              className="cursor-pointer mt-5 w-2/5 bg-blue-300 text-black hover:bg-blue-200 text-marble-200 font-md rounded px-1 sm:px-1 py-1 sm:py-1">
              Book a Demo
            </button>
          </div>
        </div>
      </CSSTransition>))}
      <ReactModal isOpen={demoModalOpen}
        contentLabel="Request a Demo"
        overlayClassName="demo-modal-overlay"
        className="demo-modal"
        onRequestClose={() => setDemoModalOpen(false)}>
        <DemoRequestModal onRequestClose={() => setDemoModalOpen(false)}></DemoRequestModal>
      </ReactModal>
    </section>
  );
};

export default LandingCarouselfeature;
