import * as React from "react";

// markup
const Stars = ({ rating, offColor, starClass }) => {
  const flooredRating = Math.floor(rating);
  const fiveArray = Array.from(Array(5).keys());
  return (
    <div className="flex flex-row items-center">
      {fiveArray.map((i) => (<svg key={i} className={starClass} viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.8004 0.146484L18.0949 10.2553H28.7538L20.1223 16.4898L23.4318 26.5987L14.8004 20.3493L6.16891 26.5987L9.46347 16.4898L0.832031 10.2553H11.5058L14.8004 0.146484Z" fill={i < flooredRating ? '#FF6B00' : offColor} />
        </svg>))}
    </div>
  )
}

export default Stars
