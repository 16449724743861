import * as React from "react";
import InView from "react-intersection-observer";
import * as animatedSearchStyles from "./animated-search-results.module.css";
import searchBox from '../images/google-search-box.svg';

// markup
export default class AnimatedSearchResults extends React.Component {
  _timeout = null;
  dirty = false;

  constructor(props) {
    super(props);
    let results = props.results || [];
    const defaultResults = [
      'Acme, Inc',
      'ABC Business',
      'XYZ Stores'
    ];
    results = defaultResults.map((n, i) => {
      return results[i] || n;
    });
    this.className = props.className;
    this.searchText = props.searchText;
    this.state = {
      searchText: '',
      results: results,
      visibleResults: [false, false, false]
    };
  }

  startAnimation() {
    if (this._timeout || !window || (this.state.searchText === this.searchText && this.state.visibleResults.findIndex((v) => !v) === -1)) { return; }
    this.dirty = true;
    this._timeout = window.setTimeout(() => {
      let newText = this.state.searchText;
      if (newText === this.searchText) {
        let hiddenIndex = this.state.visibleResults.findIndex((v) => !v);
        if (hiddenIndex < 0) {
          this._timeout = null;
          return;
        }
        let vr = this.state.visibleResults;
        vr[hiddenIndex] = true;
        this.setState({
          visibleResults: vr
        }, () => {
          this._timeout = null;
          this.startAnimation();
        });
        return;
      }
      newText = newText + this.searchText.substring(this.state.searchText.length, this.state.searchText.length + 1);
      this.setState({
        searchText: newText
      }, () => {
        this._timeout = null;
        this.startAnimation();
      });
    }, 50);
  }
  resetAnimation() {
    if (this._timeout && window) {
      window.clearTimeout(this._timeout);
      this._timeout = null;
    }
    if (!this.state.searchText || !this.dirty) { return; }
    this.dirty = false;
    setTimeout(() => {
      this.setState({
        searchText: '',
        visibleResults: [false, false, false]
      });
    });
  }

  render() {
    return (
      <InView>
        {({ inView, ref, entry }) => {
          if (inView) {
            this.startAnimation();
          } else {
            this.resetAnimation();
          }
          return (<div ref={ref} className={`text-primary-500 flex flex-col items-start max-w-full ${this.className}`}>
            <div className={animatedSearchStyles.searchboxcontainer} style={{backgroundImage: "url('" + searchBox + "')"}}>
              <span className={animatedSearchStyles.searchbox}>{this.state.searchText}<div className={animatedSearchStyles.cursor}>|</div></span>
            </div>
            <ul className="flex flex-col items-start max-w-full">
              {this.state.results.map((r, i) => (<li key={i} className={`${animatedSearchStyles.searchresult} ${this.state.visibleResults[i] ? animatedSearchStyles.visible : ''}`}>
                <div className={`${animatedSearchStyles.searchresultcontent}`}>{r}</div>
              </li>))}
            </ul>
          </div>);
        }}
      </InView>
    )
  }
}
