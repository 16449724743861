import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import FiveStarsLite from '../images/fivestars-lite.svg'

export const query = graphql`
  fragment LandingAppintegrationContent on FiveoakCMS_ComponentLandingAppintegration {
    __typename
    heading
    title
    images {
      url
      publicUrl
      provider
      alternativeText
      ext
      imageFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

// markup
const LandingAppIntegration = ({ content }) => {
  return (
    <section>
      <div className="text-center bg-blue-300 flex flex-col text-white justify-center align-center pb-14">
        <h1 className="flex justify-center md:mt-8 mt-6 md:mb-2 mb-1">
          <img src={FiveStarsLite} alt='five_stars' />
        </h1>
        <div className="max-w-full mx-auto text-4xl font-black">
          {content?.heading}
        </div>
        <div className="max-w-full mx-auto text-xs font-serif mt-4 md:mt-6">
          {content?.title}
        </div>
        <div className='w-full flex justify-center mt-2 md:mt-4'>
          {content?.images && (content?.images.length || '') && <ul className='flex w-7/12 justify-around'>
            {content?.images.map((item, i) => {
              const bulletImage = getImage(item.imageFile);
              return (<li key={i} className="flex flex-row items-center">
                {bulletImage &&
                  <GatsbyImage image={bulletImage}
                    alt={item.image?.alternativeText || ''}
                    className="mr-2 w-7/12: sm:w-10/12 h-auto" />
                }
                {!bulletImage &&
                  <img src={item.image.publicUrl}
                    alt={item.image?.alternativeText || ''}
                    className="mr-2 w-7/12: sm:w-10/12 h-auto" loading="lazy" />
                }
              </li>);
            })}
          </ul>}
        </div>
      </div>
    </section>
  );
};

export default LandingAppIntegration;
