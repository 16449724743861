import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import ReactMarkdown from 'react-markdown';
import ReactModal from 'react-modal';
import DemoRequestModal from './demo-request-modal';
import AnimatedSearchResults from './animated-search-results';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';

ReactModal.setAppElement('#___gatsby');

export const query = graphql`
  fragment LandingHeronearmeContent on FiveoakCMS_ComponentLandingHeronearme {
    __typename
    animatedsearchterm
    body
    searchresultone
    searchresulttwo
    searchresultthree
    title
    image {
      url
      provider
      alternativeText
      ext
      imageFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`;

// markup
class LandingHeroNearMe extends React.Component {
  constructor(props) {
    super(props);
    this.content = props.content;
    this.data = props.data;
    this.state = {
      demoModalOpen: false,
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }
  handleModalOpen() {
    this.setState({
      demoModalOpen: true,
    });
  }
  handleModalClose() {
    this.setState({
      demoModalOpen: false,
    });
  }
  render() {
    let image;
    if (this.content.image) {
      image = getImage(this.content.image.imageFile);
    }
    return (
      <section className={`text-primary-300 lg:pb-24 pb-8 pt-16 px-5`}>
        <div className="max-w-screen-xl lg:px-10 mx-auto flex flex-col-reverse items-center lg:flex-row lg:items-stretch">
          <div className="lg:w-1/2 max-w-md flex flex-col items-start justify-center">
            {this.content.title && (
              <h1 className="md:text-6xl text-5xl font-black text-primary-500 leading-tight md:leading-tight md:mb-8 mb-5">
                {this.content.title}
              </h1>
            )}
            {this.content.body && (
              <div className="text-xl">
                <ReactMarkdown>{this.content.body}</ReactMarkdown>
              </div>
            )}
            <button
              type="button"
              onClick={this.handleModalOpen}
              className="inline-block mt-4 cursor-pointer bg-orange-500 hover:bg-orange-600 text-white font-bold rounded-full px-12 py-3 flex flex-row items-center"
            >
              <svg
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  style={{ fill: 'currentColor' }}
                  d="M7.42188 2.11719C7.03125 2.50781 7.07031 3.09375 7.42188 3.48438L12.1484 7.9375H0.9375C0.390625 7.9375 0 8.36719 0 8.875V10.125C0 10.6719 0.390625 11.0625 0.9375 11.0625H12.1484L7.42188 15.5547C7.07031 15.9453 7.07031 16.5312 7.42188 16.9219L8.28125 17.7812C8.67188 18.1328 9.25781 18.1328 9.60938 17.7812L17.2266 10.1641C17.5781 9.8125 17.5781 9.22656 17.2266 8.83594L9.60938 1.25781C9.25781 0.90625 8.67188 0.90625 8.28125 1.25781L7.42188 2.11719Z"
                />
              </svg>
              <span className="ml-2">Request a Demo</span>
            </button>
          </div>
          <div className="relative z-0 lg:w-1/2 grow flex flex-row items-center lg:pl-11 lg:pl-20 xl:pl-36 pt-11 pb-16 lg:pt-36 lg:pb-48 max-w-full">
            {image && (
              <GatsbyImage
                image={image}
                alt={this.content.image.alternativeText || ''}
                className="absolute z-0 left-0 top-0 w-full h-full"
                objectPosition="right center"
                loading="eager"
                objectFit="contain"
              />
            )}
            {this.content.animatedsearchterm && (
              <AnimatedSearchResults
                searchText={this.content.animatedsearchterm}
                results={[
                  this.content.searchresultone,
                  this.content.searchresulttwo,
                  this.content.searchresultthree,
                ]}
                className="relative z-10"
              />
            )}
          </div>
        </div>
        <ReactModal
          isOpen={this.state.demoModalOpen}
          contentLabel="Request a Demo"
          overlayClassName="demo-modal-overlay"
          className="demo-modal"
          onRequestClose={this.handleModalClose}
        >
          <DemoRequestModal
            onRequestClose={this.handleModalClose}
          ></DemoRequestModal>
        </ReactModal>
      </section>
    );
  }
}

const renderedHero = ({ content }) => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            apiURL
          }
        }
      }
    `}
    render={(data) => <LandingHeroNearMe data={data} content={content} />}
  />
);

export default renderedHero;
