import { graphql } from "gatsby";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import ReactModal from 'react-modal';
import DemoRequestModal from "./demo-request-modal";
import * as landingVideoFooterStyles from "./landing-video-footer.module.css";

ReactModal.setAppElement('#___gatsby');

export const query = graphql`
  fragment LandingVideofooterContent on FiveoakCMS_ComponentLandingVideofooter {
    __typename
    title
    videourl
  }
`

// markup
export default class LandingVideoFooter extends React.Component {
  constructor(props) {
    super(props);
    this.content = props.content;
    this.state = {
      demoModalOpen: false
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }
  handleModalOpen() {
    this.setState({
      demoModalOpen: true
    });
  }
  handleModalClose() {
    this.setState({
      demoModalOpen: false
    });
  }
  render() {
    return (
      <section className={`text-primary-500 lg:pb-24 pb-8 pt-16 px-5 ${landingVideoFooterStyles.starsBackground}`}>
        <div className="max-w-3xl mx-auto mb-24">
          <div className="aspect-w-16 aspect-h-9">
            {this.content.videourl && <iframe src={this.content.videourl}
              frameBorder="0"
              title="Landing Footer Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              loading="lazy"
              allowFullScreen></iframe>}
          </div>
        </div>
        <div className="flex flex-col items-center">
          {this.content.title && <div className="text-4xl text-center mb-7">
            <ReactMarkdown>{this.content.title}</ReactMarkdown>
          </div>}
          <button type="button"
            onClick={this.handleModalOpen}
            className="inline-block cursor-pointer bg-orange-500 hover:bg-orange-600 text-white font-bold rounded-full px-12 py-3">
              Request a Demo
          </button>
        </div>
        <ReactModal isOpen={this.state.demoModalOpen}
          contentLabel="Request a Demo"
          overlayClassName="demo-modal-overlay"
          className="demo-modal"
          onRequestClose={this.handleModalClose}>
          <DemoRequestModal onRequestClose={this.handleModalClose}></DemoRequestModal>
        </ReactModal>
      </section>
    )
  }
}
