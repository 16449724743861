import { graphql } from "gatsby";
import * as React from "react";

export const query = graphql`
  fragment LandingRawhtmlContent on FiveoakCMS_ComponentPageRawhtml {
    __typename
    html
  }
`
// markup
const RawHTML = ({ content }) => {
  return (
    <div className="contents"
      dangerouslySetInnerHTML={{__html: content.html}}></div>
  )
}

export default RawHTML
