import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import * as landingHeroStyles from './landing-hero.module.css';
import DemoRequestForm from './demo-request-form';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export const query = graphql`
  fragment LandingHeroContent on FiveoakCMS_ComponentLandingHero {
    __typename
    titleprimary
    titlesecondary
    subtitle
    image {
      url
      provider
      alternativeText
      ext
      imageFile {
        childImageSharp {
          gatsbyImageData(width: 766)
        }
      }
    }
  }
`;

// markup
const LandingHero = ({ hero }) => {
  const image = getImage(hero.image.imageFile);
  return (
    <section
      className={`text-primary-300 lg:pb-24 pb-8 pt-16 px-5 ${landingHeroStyles.starsBackground}`}
    >
      <div className="text-center pb-14">
        <h1 className="md:text-6xl text-5xl font-black text-primary-500 leading-tight md:leading-tight md:mb-8 mb-5">
          {hero.titleprimary}{' '}
          <span className="text-orange-500 block">{hero.titlesecondary}</span>
        </h1>
        <div className="max-w-lg mx-auto text-xl">
          <ReactMarkdown>{hero.subtitle}</ReactMarkdown>
        </div>
      </div>

      <div className="flex lg:flex-row lg:items-stretch lg:justify-center flex-col">
        <div className="flex flex-col items-center lg:justify-center lg:mb-0 mb-20">
          <GatsbyImage
            image={image}
            alt={hero.image.alternativeText || ''}
            loading="eager"
          />
        </div>
        <div className="shrink-0 lg:ml-12 lg:w-96 w-full lg:max-w-full max-w-xl lg:mr-0 mx-auto bg-app rounded-3xl lg:py-4 py-7 px-7 flex items-center">
          <DemoRequestForm whiteBg={false}></DemoRequestForm>
        </div>
      </div>
    </section>
  );
};

export default LandingHero;
