import * as React from "react";
import '@fontsource/lato';
import '@fontsource/lato/700.css';
import '@fontsource/lato/900.css';

// markup
const Layout = ({ children }) => {
  return (
    <main>
      {children}
    </main>
  )
}

export default Layout
