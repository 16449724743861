import { graphql } from "gatsby";
import * as React from "react";
import ReactMarkdown from "react-markdown";
import * as landingVideoStyles from "./landing-video.module.css";

export const query = graphql`
  fragment LandingVideoContent on FiveoakCMS_ComponentLandingVideo {
    __typename
    title
    videourl
  }
`

// markup
const LandingVideo = ({ content }) => {
  return (
    <section className={`text-primary-500 lg:pb-24 pb-8 pt-16 px-5  ${landingVideoStyles.starsBackground}`}>
        <div className="flex flex-col items-center">
          {content.title && <div className="text-4xl text-center mb-14">
            <ReactMarkdown>{content.title}</ReactMarkdown>
          </div>}
        </div>
        <div className="max-w-3xl mx-auto">
          <div className="aspect-w-16 aspect-h-9">
            {content.videourl && <iframe src={content.videourl}
              frameBorder="0"
              title="Landing Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              loading="lazy"
              allowFullScreen></iframe>}
          </div>
        </div>
      </section>
  )
}

export default LandingVideo
