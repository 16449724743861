import * as React from "react"
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import * as contentImageStyles from "./landing-content-image.module.css";

export const query = graphql`
  fragment LandingContentimageContent on FiveoakCMS_ComponentLandingContentimage {
    __typename
    descriptor
    title
    body
    direction
    image {
      url
      publicUrl
      provider
      alternativeText
      ext
      imageFile {
        childImageSharp {
          gatsbyImageData(
            width: 700
          )
        }
      }
    }
    backgroundimage {
      url
      publicUrl
      provider
      alternativeText
      ext
      imageFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    imagelist {
      text
      image {
        url
        publicUrl
        provider
        alternativeText
        ext
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

// markup
const LandingContentImage = ({ content }) => {
  const image = getImage(content.image.imageFile);
  let backgroundImage;
  if (content.backgroundimage) {
    backgroundImage = getImage(content.backgroundimage.imageFile);
  }
  return (
    <div className="relative">
      {backgroundImage && <GatsbyImage image={backgroundImage}
        alt={content.backgroundimage.alternativeText || ''}
        objectPosition="50% 0"
        objectFit="cover"
        className="absolute left-0 w-full top-0 h-full z-0" />}
      {!backgroundImage && content.backgroundimage && <img src={content.backgroundimage.publicUrl}
        alt={content.backgroundimage.alternativeText || ''}
        className="absolute left-0 w-full top-0 h-full z-0 object-top object-cover"
        loading="lazy" />}
      <section className="relative z-10 text-primary-500 text-lg py-12 px-5 flex flex-col lg:flex-row items-center max-w-screen-xl mx-auto">
        <div className={'order-2 lg:pl-10 lg:w-2/5 ' + (content.direction === 'image_on_right' ? 'lg:order-1' : '')}>
          {content.descriptor && <div className="text-orange-500 text-center lg:text-left text-sm uppercase font-bold">{content.descriptor}</div>}
          {content.title && <div className="text-center lg:text-left text-4xl whitespace-pre-wrap mb-7">
          <ReactMarkdown className={contentImageStyles.title}>{content.title}</ReactMarkdown>
          </div>}
          {content.body && <div className="mb-8 text-primary-300">{content.body}</div>}
          {content.imagelist && (content.imagelist.length || '') && <ul>
            {content.imagelist.map((item, i) => {
              const bulletImage = getImage(item.image.imageFile);
              return (<li key={i} className="flex flex-row items-center mb-5">
                { bulletImage &&
                  <GatsbyImage image={bulletImage}
                    alt={item.image.alternativeText || ''}
                    className="mr-2" />
                }
                { !bulletImage &&
                  <img src={item.image.publicUrl}
                    alt={item.image.alternativeText || ''}
                    className="mr-2" loading="lazy" />
                }
                <ReactMarkdown className="text-primary-300">{item.text}</ReactMarkdown>
              </li>);
            })}
          </ul>}
        </div>
        <div className={'order-1 lg:pl-10 lg:pr-5 lg:w-3/5 mx-auto mb-8 lg:mb-0' + (content.direction === 'image_on_right' ? 'lg:order-2' : '')}>
          <GatsbyImage image={image} alt={content.image.alternativeText} />
        </div>
      </section>
    </div>
  )
}

export default LandingContentImage
