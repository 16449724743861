import * as React from 'react';
import ReactModal from 'react-modal';
import logo from '../images/fiveoak-logo.svg';
import appStoreBadge from '../images/download-on-appstore.svg';
import googlePlayBadge from '../images/get-it-on-google-play.svg';
import { Link } from 'gatsby';

ReactModal.setAppElement('#___gatsby');

// markup
export default class FooterAlpha extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      demoModalOpen: false,
      hasSocialProof: props.hasSocialProof,
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }
  handleModalOpen() {
    this.setState({
      demoModalOpen: true,
    });
  }
  handleModalClose() {
    this.setState({
      demoModalOpen: false,
    });
  }
  scrollToSection = (e, section) => {
    e.preventDefault(); window.location.replace(`/#${section}`)
  }
  render() {
    return (
      <footer
        className={`h-20 w-full sm:px-12 flex flex-row items-center justify-between bg-marble-400 py-16 px-8`}
      >
        <div className='flex flex-col gap-2'>
          <Link to="/" title="Fiveoak">
            <img src={logo} alt="Fiveoak" className="w-32 h-auto" />
          </Link>
          <div>
            &copy; {new Date().getFullYear()} fiveoak. All rights reserved
          </div>
        </div>
        <div className="hidden sm:flex flex-row w-2/3 text-xs md:text-xs lg:text-base font-bold justify-between">
          <div onClick={e => this.scrollToSection(e, 'features')} className="cursor-pointer">Features</div>
          {/* <div className="cursor-pointer">Solutions</div> */}
          <div onClick={e => this.scrollToSection(e, 'success')} className="cursor-pointer">Success Stories</div>
          {/* <div className="cursor-pointer">Pricing</div> */}
          <div onClick={e => this.scrollToSection(e, 'support')} className="cursor-pointer">Support</div>
          {/* <div className="cursor-pointer">About Us</div> */}
        </div>
        <div className="flex scale-75 flex-row flex-wrap items-center gap-1 mb-5">
          <a
            href="https://apps.apple.com/us/app/fiveoak/id1588321916"
            target="_blank"
            rel="noreferrer"
            className="block px-1"
          >
            <img
              src={appStoreBadge}
              alt="Download on the App Store"
              className="w-auto h-12"
            />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.fiveoak.app.twa"
            target="_blank"
            rel="noreferrer"
            className="block px-1"
          >
            <img
              src={googlePlayBadge}
              alt="Get It On Google Play"
              className="w-auto h-12"
            />
          </a>
        </div>
      </footer>
    );
  }
}
