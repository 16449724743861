import { Link } from "gatsby";
import * as React from "react";
import ReactModal from 'react-modal';
import logo from "../images/fiveoak-logo.svg";
import userLogo from '../images/user.svg'
import DemoRequestModal from "./demo-request-modal";

ReactModal.setAppElement('#___gatsby');

// markup
export default class HeaderAlpha extends React.Component {
  constructor(props) {
    super(props);
    let demoModalOpen = false;
    if (typeof window !== "undefined") {
      const urlSearchParams = new URLSearchParams(window.location.search);
      demoModalOpen = urlSearchParams.get('demopopup') === 'open';
    }
    this.state = {
      fixed: !!this.props.fixed,
      demoModalOpen: demoModalOpen
    };

    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }
  handleModalOpen() {
    this.setState({
      demoModalOpen: true
    });
  }
  handleModalClose() {
    this.setState({
      demoModalOpen: false
    });
  }
  scrollToSection = (e, section) => {
    e.preventDefault(); window.location.replace(`/#${section}`)
  }
  render() {
    return (
      <div className={`${this.state.fixed ? 'h-20' : ''}`}>
        <header className={`h-20 w-full px-4 sm:px-12 bg-white flex flex-row items-center justify-between ${this.state.fixed ? 'fixed z-10' : ''}`}>
          <Link to="/" className="mr-2">
            <img src={logo} alt="Fiveoak" className="w-32 h-auto" />
          </Link>
          <div className="hidden sm:flex flex-row w-1/2 text-xs md:text-xs lg:text-base font-bold justify-between">
            <div onClick={e => this.scrollToSection(e,'features')} className="cursor-pointer">Features</div>
            {/* <div className="cursor-pointer">Solutions</div> */}
            <div onClick={e => this.scrollToSection(e,'success')} className="cursor-pointer">Success Stories</div>
            {/* <div className="cursor-pointer">Pricing</div> */}
            <div onClick={e => this.scrollToSection(e,'support')} className="cursor-pointer">Support</div>
            {/* <div className="cursor-pointer">About Us</div> */}
          </div>
          <div className="flex flex-row gap-2 items-center ml-2">
            <a href="https://app.fiveoak.com/">
              <img src={userLogo} alt="Login" className="w-5 h-auto" />
            </a>
            <button type="button"
              onClick={this.handleModalOpen}
              className="inline-block ml-2 cursor-pointer bg-blue-300 hover:bg-blue-200 text-white font-bold rounded px-2 sm:px-6 py-1 sm:py-2">
                Book a Demo
            </button>
          </div>
        </header>
        <ReactModal isOpen={this.state.demoModalOpen}
          contentLabel="Request a Demo"
          overlayClassName="demo-modal-overlay"
          className="demo-modal"
          onRequestClose={this.handleModalClose}>
          <DemoRequestModal onRequestClose={this.handleModalClose}></DemoRequestModal>
        </ReactModal>
      </div>
    )
  }
}
