import { graphql } from "gatsby";
import * as React from "react";

export const query = graphql`
  fragment LandingSocialproofContent on FiveoakCMS_ComponentLandingSocialproof {
    __typename
    position
    ratings
  }
`

// markup
const LandingSocialProof = ({ options }) => {
  const src = `https://social-proof.fiveoak.com/?rating=5${options.ratings === 'fourandfive' ? '&rating=4' : ''}&location_id=397&review_site_id=1`;
  return (
    <iframe src={src}
      title="Fiveoak Reviews"
      className={`fixed max-w-full z-10 w-96 h-32 transform -translate-x-1/2 sm:translate-x-0 left-1/2 ${options.position === 'bottomright' ? 'sm:right-0 sm:left-auto bottom-0' : 'sm:left-0 bottom-0'}`} />
  )
}

export default LandingSocialProof
