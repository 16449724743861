import React from "react";
import Layout from "../components/layout";
import LandingHero from "../components/landing-hero";
import LandingLogos from "../components/landing-logos";
import LandingContentImage from "../components/landing-content-image";
import LandingStepsList from "../components/landing-steps-list";
import LandingVideoFooter from "../components/landing-video-footer";
import Footer from "../components/footer";
import Header from "../components/header";
import { Helmet } from "react-helmet";
import LandingSuccessStories from "../components/landing-success-stories";
import LandingHeroNearMe from "../components/landing-hero-near-me";
import LandingFinalcta from "../components/landing-final-cta";
import RawHTML from "./rawhtml";
import LandingSocialProof from "./landing-social-proof";
import { graphql } from "gatsby";
import LandingVideo from "./landing-video";
import CookieConsent from 'react-cookie-consent';
import { useLocation } from '@reach/router';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import LandingContentImageAlpha from "./landing-content-image-alpha";
import LandingCarouselfeature from "./landing-carousel-feature";
import LandingCarouselstory from "./landing-carousel-story";
import LandingAppIntegration from "./app-integration";
import LandingFaq from "./landing-faq";
import HeaderAlpha from "./header-alpha";
import FooterAlpha from "./footer-alpha";

export const query = graphql`
  fragment PageMetaContent on FiveoakCMS_ComponentPageMeta {
    name
    value
    __typename
  }
  fragment PageScriptContent on FiveoakCMS_ComponentPageScript {
    src
    defer
    __typename
  }
  fragment PageRawjsContent on FiveoakCMS_ComponentPageRawjs {
    js
    __typename
  }
`

const PageBuilder = ({ pageData }) => {
  const location = useLocation();
  let title = pageData?.meta.find((m) => m.__typename === 'FiveoakCMS_ComponentPageMeta' && m.name.toLowerCase() === 'title');
  if (title) {
    title = title.value;
  } else {
    title = pageData?.title;
  }

  var hasSocialProof = false;
  const homepageTitle = 'Fiveoak Review Management for Local Businesses'
  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>{title}</title>
        {pageData?.meta && pageData?.meta.map((m, i) => {
          if (m.__typename === 'FiveoakCMS_ComponentPageMeta' && m.name.toLowerCase() !== 'title') {
            return (<meta key={m.name} name={m.name} content={m.value} />)
          } else if (m.__typename === 'FiveoakCMS_ComponentPageRawjs') {
            return (<script key={i} innerHTML={m.js} />)
          } else if (m.__typename === 'FiveoakCMS_ComponentPageScript') {
            return (<script key={i} src={m.src} defer={m.defer} />)
          }
          return '';
        })}
      </Helmet>
      {pageData?.headertype !== 'none' && (title===homepageTitle? <HeaderAlpha fixed={pageData?.headertype === 'fixed'} />: <Header  fixed={pageData?.headertype === 'fixed'} />)}
      {pageData?.content.map((comp, i) => {
        if(comp.__typename === 'FiveoakCMS_ComponentLandingContentimage') {
          return(
            <LandingContentImageAlpha key={i} content={comp} />
          )
        }
        else if(comp.__typename === 'FiveoakCMS_ComponentLandingCarousel' && comp.title==='features') {
          return(
            <LandingCarouselfeature key={i} carousel={comp} />
          )
        }
        // else if(comp.__typename === 'FiveoakCMS_ComponentLandingCarousel' && comp.title==='Success Stories') {
        //   return(
        //     <LandingCarouselstory key={i} carousel={comp} />
        //   )
        // }
        
        else if(comp.__typename === 'FiveoakCMS_ComponentLandingAppintegration') {
          return(
            <LandingAppIntegration key={i} content={comp} />
          )
        }
        else if(comp.__typename === 'FiveoakCMS_ComponentLandingFaq') {
          return(
            <LandingFaq key={i} faqs={comp} />
          )
        }
        else if (comp.__typename === 'FiveoakCMS_ComponentLandingHero') {
          return (
            <LandingHero key={i} hero={comp} />
          )
        } else if (comp.__typename === 'FiveoakCMS_ComponentLandingLogos') {
          return (
            <LandingLogos key={i} logos={comp} />
          )
        } else if (comp.__typename === 'FiveoakCMS_ComponentLandingContentimage') {
          return (
            <LandingContentImage key={i} content={comp} />
          )
        } else if (comp.__typename === 'FiveoakCMS_ComponentLandingStepslist') {
          return (
            <LandingStepsList key={i} content={comp} />
          )
        } else if (comp.__typename === 'FiveoakCMS_ComponentLandingVideofooter') {
          return (
            <LandingVideoFooter key={i} content={comp} />
          )
        } else if (comp.__typename === 'FiveoakCMS_ComponentLandingSuccessstories') {
          return (
            <LandingSuccessStories key={i} content={comp} />
          )
        } else if (comp.__typename === 'FiveoakCMS_ComponentLandingHeronearme') {
          return (
            <LandingHeroNearMe key={i} content={comp} />
          )
        } else if (comp.__typename === 'FiveoakCMS_ComponentLandingSocialproof') {
          hasSocialProof = true;
          return (
            <LandingSocialProof key={i} options={comp} />
          )
        } else if (comp.__typename === 'FiveoakCMS_ComponentPageRawhtml') {
          return (
            <RawHTML key={i} content={comp} />
          )
        } else if (comp.__typename === 'FiveoakCMS_ComponentLandingFinalcta') {
          return (
            <LandingFinalcta key={i} content={comp} />
          )
        } else if (comp.__typename === 'FiveoakCMS_ComponentLandingVideo') {
          return (
            <LandingVideo key={i} content={comp} />
          )
        }
        return '';
      })}
      {pageData?.includefooter && (title===homepageTitle? <FooterAlpha hasSocialProof={hasSocialProof} />: <Footer hasSocialProof={hasSocialProof} />)}
      <CookieConsent buttonStyle={{color: '#fff', background: '#ff6b00'}}
        declineButtonStyle={{background: 'transparent', color: '#c8c8c8'}}
        style={{background: '#000'}}
        cookieName="gatsby-gdpr-google-tagmanager"
        enableDeclineButton
        declineButtonText="Decline"
        buttonText="Accept"
        onAccept={() => {
          initializeAndTrack(location);
        }}>
        <p className="text-xs">This website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you.
        We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website. <a href="https://www.cookiesandyou.com/" className="text-gray-200 underline" target="_blank" rel="noreferrer">Learn more</a></p>

        <p className="mt-2 text-xs">If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.</p>
      </CookieConsent>
    </Layout>
  );
};

export default PageBuilder;
