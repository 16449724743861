import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Arrow from '../images/arrow.svg'
import Cloud from '../images/cloud.svg'
import Paperclip from '../images/paperclip.svg'
import Send from '../images/send.svg'

const RECAPTCHA_SITE_KEY = '6LcFDdUaAAAAAG_zcXDnQhgPg7XlKAQ5qHu2dU5i';

export const query = graphql`
  fragment LandingFaqContent on FiveoakCMS_ComponentLandingFaq {
    __typename
    heading
    title
    subtitle
    faqitem {
      q
      a
    }
  }
`;

const lableStyle = { display: 'flex', color: '#666', fontWeight: '400', fontSize: '16px', marginBottom: '8px' }
const inputStyle = { backgroundColor: '#FBFBFB', height: '32px', borderRadius: '4px', border: '1px solid #CCC' }
const initialForm = {
  account: '',
  email: '',
  phone: '',
  subject: '',
  description: '',
  browser: '',
  inquiryType: ''
}

// markup
const LandingFaq = ({ faqs }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          apiURL
        }
      }
    }
  `);

  const fileInputRef = React.useRef();
  const [selectedIdx, setSelectedIdx] = React.useState(-1)
  const [attachments, setAttachments] = React.useState([]);
  const [hoveredAttach, setHoveredAttach] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [form, setForm] = React.useState(initialForm)

  const lazyLoadCaptchaLib = () => {
    const scriptId = 'recaptcha-script';
    if (document.getElementById(scriptId)) {
      return; // Return if script is already loaded
    }
    var head = document.getElementsByTagName('head')[0];
    var script = document.createElement('script');
    script.id = scriptId;
    script.type = 'text/javascript';
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LcFDdUaAAAAAG_zcXDnQhgPg7XlKAQ5qHu2dU5i';
    head.appendChild(script);
  }

  const handleFileInputChange = (event) => {
    const files = event.target.files
    // if(files.length > 6) {
    //   alert('Maximum 6 files allowed at a time.')
    //   return
    // }
    files.length && (setAttachments([...files]))
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('account', form.account);
    formData.append('email', form.email);
    formData.append('phone', form.phone);
    formData.append('subject', form.subject);
    formData.append('description', form.description);
    formData.append('browser', form.browser);
    formData.append('inquiryType', form.inquiryType);

    attachments.forEach((file, index) => {
      formData.append(`attachments[${index}]`, file);
    });
    handleFormSubmit(formData)
  };

  const handleFormSubmit = (formData) => {
    setLoading(true)
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' }).then((token) => {
        formData.append('g-recaptcha-response', token);
        setLoading(true)
        fetch(`${data.site.siteMetadata.apiURL}/supportrequests/website`, {
          method: 'POST',
          body: formData,
        }).then(res => {
          if (!res.ok) {
            throw new Error(res.statusText);
          }
          return res;
        }).then(res => res.json()).then(result => {
          if (window.dataLayer) {
            window.dataLayer.push({ 'event': 'form-demo-submit' });
          }
          setLoading(false)
          setForm(initialForm)
          setAttachments([])
        }).catch(error => {
          setLoading(false)
          console.log(error);
        });
      });
    });
  }
  console.log(loading)
  return (
    <>
      <section
        id='support'
        className={`lg:pb-6 pb-1 pt-8 px-5 bg-purple-100`}
      >
        <div className="text-center mt-10 flex flex-col justify-center align-center pb-2">
          <h1 className="flex justify-center text-xs md:mb-2 mb-1">
            <div className='w-70 bg-marble-400 h-auto px-2 py-1'>{faqs.heading}</div>
          </h1>
          <div className="max-w-full mx-auto text-4xl font-black">
            {faqs.title}
          </div>
        </div>
        <div className="flex mt-5 flex-col items-center">
          <div className="shrink-0 text-center w-full lg:max-w-full max-w-xl lg:mr-0 mx-auto flex justify-center">
            <div className="text-xl max-w-3xl mx-auto mt-2">
              {faqs.subtitle}
            </div>
          </div>
        </div>
        <div className="flex mt-20 flex-col items-center lg:justify-center mb-10">
          <ul className='w-8/12 sm:w-6/12'>
            {faqs.faqitem.map((faq, index) => <li key={index} onClick={() => { if (selectedIdx === index) setSelectedIdx(-1); else setSelectedIdx(index) }} className='flex w-full flex-col cursor-pointer items-center'>
              <div className={`flex w-full pb-2 ${index === selectedIdx ? 'font-bold' : ''} border-b-marble-500 border-b justify-between`}>
                <div>{faq.q}</div>
                <img src={Arrow} alt='Arrow' className={`${index === selectedIdx ? 'rotate-180' : ''}`} />
              </div>
              {index === selectedIdx && <div className='text-xs justify-between w-full flex pb-3'>{faq.a}</div>}
            </li>)}
          </ul>
        </div>
      </section>
      <section
        id='support'
        className={`lg:pb-6 pb-1 pt-8 px-5 bg-purple-100`}
      >
        <div className="text-center mt-10 flex flex-col justify-center align-center pb-2">
          <div className="max-w-full mx-auto text-3xl">
            {'Submit A Request'}
          </div>
          <div className="flex mt-5 flex-col items-center lg:justify-center mb-10">
            <form onSubmit={handleSubmit} className='w-full lg:w-6/12 sm:w-10/12 bg-white rounded-lg p-6' style={{ borderRadius: '16px', display: 'flex', padding: '36px', flexDirection: 'column' }}>

              <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
                <div style={lableStyle}>Account</div>
                <input required onFocus={lazyLoadCaptchaLib} value={form.account} onChange={(e) => setForm(prev => ({ ...prev, account: e.target.value }))} style={{ width: '60%', backgroundColor: '#FBFBFB', height: '32px', borderRadius: '4px', border: '1px solid #CCC' }} />
              </div>

              <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '60%' }}>
                  <div style={lableStyle}>Email ID</div>
                  <input required type={'email'} onFocus={lazyLoadCaptchaLib} value={form.email} onChange={(e) => setForm(prev => ({ ...prev, email: e.target.value }))} style={inputStyle} />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', width: '35%' }}>
                  <div style={lableStyle}>Phone no</div>
                  <input onFocus={lazyLoadCaptchaLib} value={form.phone} type={'tel'} onChange={(e) => setForm(prev => ({ ...prev, phone: e.target.value }))} style={inputStyle} />
                </div>
              </div>

              <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
                <div style={lableStyle}>Subject</div>
                <input required onFocus={lazyLoadCaptchaLib} value={form.subject} onChange={(e) => setForm(prev => ({ ...prev, subject: e.target.value }))} style={{ width: '100%', backgroundColor: '#FBFBFB', height: '32px', borderRadius: '4px', border: '1px solid #CCC' }} />
              </div>

              <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
                <div style={lableStyle}>Description</div>
                <textarea required onFocus={lazyLoadCaptchaLib} value={form.description} onChange={(e) => setForm(prev => ({ ...prev, description: e.target.value }))} rows={4} style={{ resize: 'none', width: '100%', backgroundColor: '#FBFBFB', borderRadius: '4px', border: '1px solid #CCC' }} />
              </div>

              <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                  <div style={lableStyle}>Choose Browser</div>
                  <select onFocus={lazyLoadCaptchaLib} value={form.browser} onChange={(e) => setForm(prev => ({ ...prev, browser: e.target.value }))} style={inputStyle}>
                    <option value={''}></option>
                    <option value={'Safari'}>Safari</option>
                    <option value={'Google chrome'}>Google chrome</option>
                    <option value={'Mozilla'}>Mozilla</option>
                    <option value={'Microsoft edge'}>Microsoft edge</option>
                  </select>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', width: '48%' }}>
                  <div style={lableStyle}>Inquiry Type</div>
                  <select required onFocus={lazyLoadCaptchaLib} value={form.inquiryType} onChange={(e) => setForm(prev => ({ ...prev, inquiryType: e.target.value }))} style={inputStyle}>
                    <option value={''}></option>
                    <option value={'General inquiry'}>General inquiry</option>
                    <option value={'Feature not working'}>Feature not working</option>
                    <option value={'Technical support'}>Technical support</option>
                    <option value={'Billing'}>Billing</option>
                    <option value={'Others'}>Others</option>
                  </select>
                </div>
              </div>

              {/* Attachments */}
              <div style={{ marginBottom: '20px', display: 'flex', flexDirection: 'column' }}>
                <div style={lableStyle} >Attachments</div>
                <div onClick={() => fileInputRef.current.click()} style={{ display: 'flex', cursor: 'pointer', justifyContent: 'center', gap: '10px', justifyItems: 'center', width: '60%', backgroundColor: '#FBFBFB', height: '80px', borderRadius: '4px', border: '2px dotted #CCC' }}>
                  <img src={Cloud} alt='Cloud' style={{ paddingBottom: '8px', width: '25px' }} /> <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', color: '#666', fontWeight: '400', fontSize: '16px', marginBottom: '8px' }}>Browse or Drop files here</div>
                </div>
                <input accept="image/*,.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document" onChange={handleFileInputChange} multiple={true} ref={fileInputRef} type='file' hidden />
              </div>

              {/* Attached Files */}
              {attachments.map((file, index) => (
                <div
                  key={index}
                  onMouseEnter={() => setHoveredAttach(file.name)}
                  onMouseLeave={() => setHoveredAttach(null)}
                  style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: `${hoveredAttach === file.name ? '#EEE' : ''}`, justifyItems: 'center', width: '60%', border: `${hoveredAttach === file.name ? '1px solid #CCC' : '1px solid transparent'}` }}>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <img src={Paperclip} alt='paperclip' style={{ paddingLeft: '10px' }} />
                    <div style={{ color: '#666', fontWeight: '400', fontSize: '16px' }}>{`${file.name.trim().substring(0, 30)}` + `${file.name.length > 27 ? '...' + file.name.substr(-6) : ''}`}</div>
                  </div>
                  {hoveredAttach === file.name && <button onClick={() => setAttachments(attachments.filter(f => f.name != hoveredAttach))} style={{ color: '#666', fontWeight: '400', fontSize: '12px', marginBottom: '2px', paddingRight: '10px', paddingLeft: '10px' }}>{'X'}</button>}
                </div>
              ))}

              <button disabled={loading} type={'submit'} style={{ borderRadius: '4px', marginTop: '20px', height: '40px', backgroundColor: `${loading? 'grey': '#172941'}`, width: '25%', display: 'flex', alignItems: 'center', gap: '2%', justifyItems: 'center', justifyContent: 'center' }}>
                {!loading && <img src={Send} alt='send' />}
                <div style={{ color: '#FFFFFF', fontWeight: '100', fontSize: '12px' }}>{loading ? 'Loading...' : 'Submit'}</div>
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default LandingFaq;
