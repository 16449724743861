import * as React from "react"
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import ReviewCount from "./reviewcount";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

export const query = graphql`
  fragment LandingSuccessstoriesContent on FiveoakCMS_ComponentLandingSuccessstories {
    __typename
    title
    testimonial
    testimonialcategory
    testimonialname
    testimonialtitle
    testimoniallocation
    withfiveoakcount
    withfiveoakrating
    beforefiveoakcount
    beforefiveoakrating
    casestudyurl
    backgroundimage {
      url
      publicUrl
      provider
      alternativeText
      ext
      imageFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    logo {
      url
      publicUrl
      provider
      alternativeText
      ext
      imageFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    reviewsitelogo {
      url
      publicUrl
      provider
      alternativeText
      ext
      imageFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    testimonialheadshot {
      url
      publicUrl
      provider
      alternativeText
      ext
      imageFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    casestudycard {
      withfiveoakcount
      withfiveoakrating
      beforefiveoakcount
      beforefiveoakrating
      casestudyurl
      logo {
        url
        publicUrl
        provider
        alternativeText
        ext
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      reviewsitelogo {
        url
        publicUrl
        provider
        alternativeText
        ext
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

// markup
const LandingSuccessStories = ({ content }) => {
  let backgroundImage;
  if (content.backgroundimage) {
    backgroundImage = getImage(content.backgroundimage.imageFile);
  }
  let logo;
  if (content.logo && content.logo.imageFile) {
    logo = getImage(content.logo.imageFile);
  }
  let testimonialHeadshot;
  if (content.testimonialheadshot) {
    testimonialHeadshot = getImage(content.testimonialheadshot.imageFile);
  }
  return (
    <section className="text-white w-full">
      <div className={`relative z-0 ${content.casestudycard && content.casestudycard.length ? 'pb-40' : '' } py-12 px-5`}>
        {backgroundImage && <GatsbyImage image={backgroundImage}
          className="absolute left-0 top-0 w-full h-full z-0"
          objectFit="cover"
          alt="" />}
        <div className="flex flex-col items-center relative z-10">
          {content.title && <div className="text-white font-black text-4xl text-center mb-6">{content.title}</div>}
          {logo && <GatsbyImage image={logo}
            alt={content.logo.alternativeText}
            className="my-6" />}
          {!logo && content.logo && <img src={content.logo.publicUrl}
                    alt={content.logo.alternativeText}
                    loading="lazy"
                    className="my-6" />}

          <div className="flex flex-col lg:flex-row items-stretch my-6">
            <div className="text-left lg:text-right py-3 px-4 sm:px-14">
              <div className="text-2xl font-black opacity-80 mb-3">Before Fiveoak</div>
              <ReviewCount rating={content.beforefiveoakrating}
                count={content.beforefiveoakcount}
                logo={content.reviewsitelogo}
                starOffColor="#ffffff"
                size="lg"
                color="#ffffff" />
            </div>

            <div className="hidden lg:block bg-white w-0.5"></div>

            <div className="text-left py-3 px-4 sm:px-14">
              <div className="text-2xl font-black opacity-80 mb-3">With Fiveoak</div>
              <ReviewCount rating={content.withfiveoakrating}
                count={content.withfiveoakcount}
                logo={content.reviewsitelogo}
                starOffColor="#ffffff"
                size="lg"
                color="#ffffff" />
            </div>
          </div>

          <div className="my-6 flex flex-col items-center">
            {content.testimonial && <div className="max-w-screen-sm my-4 text-center"><ReactMarkdown>{content.testimonial}</ReactMarkdown></div>}
            {testimonialHeadshot && <GatsbyImage image={testimonialHeadshot}
                    alt={content.testimonialheadshot.alternativeText}
                    className="my-2" />}
            <div className="flex flex-row my-1">
              {content.testimonialname && <span className="font-bold">{content.testimonialname}</span>}
              {content.testimonialname && content.testimonialtitle && <span className="mx-2">|</span>}
              {content.testimonialtitle && <span>{content.testimonialtitle}</span>}
            </div>
            <div className="flex flex-row my-1 text-sm">
              {content.testimonialcategory && <span>{content.testimonialcategory}</span>}
              {content.testimonialcategory && content.testimoniallocation && <span className="mx-2">|</span>}
              {content.testimoniallocation && <span>{content.testimoniallocation}</span>}
            </div>
          </div>

          {content.casestudyurl && <a href={content.casestudyurl}
            className="my-6 flex flex-row items-center cursor-pointer bg-orange-500 hover:bg-orange-600 text-white font-bold rounded-full px-3 sm:px-12 py-2 sm:py-3">
            <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path style={{fill: 'currentColor'}} d="M7.42188 2.11719C7.03125 2.50781 7.07031 3.09375 7.42188 3.48438L12.1484 7.9375H0.9375C0.390625 7.9375 0 8.36719 0 8.875V10.125C0 10.6719 0.390625 11.0625 0.9375 11.0625H12.1484L7.42188 15.5547C7.07031 15.9453 7.07031 16.5312 7.42188 16.9219L8.28125 17.7812C8.67188 18.1328 9.25781 18.1328 9.60938 17.7812L17.2266 10.1641C17.5781 9.8125 17.5781 9.22656 17.2266 8.83594L9.60938 1.25781C9.25781 0.90625 8.67188 0.90625 8.28125 1.25781L7.42188 2.11719Z" />
            </svg>
            <span className="ml-2">View Case Study</span>
          </a>}
        </div>
      </div>
      <div className="flex flex-row flex-wrap justify-center -mt-24 pb-10 relative z-0">
        {content.casestudycard && (content.casestudycard.length || '') && content.casestudycard.map((card, i) => {
          let logo;
          if (card.logo && card.logo.imageFile) {
            logo = getImage(card.logo.imageFile);
          }
          return (
            <div key={i} className="flex flex-col items-center bg-white rounded-xl shadow-xl m-3 p-6">
              {logo && <GatsbyImage image={logo}
                      alt={card.logo.alternativeText}
                      className="my-6" />}
              {card.logo && !logo && <img src={card.logo.publicUrl}
                      alt={card.logo.alternativeText}
                      loading="lazy"
                      className="my-6" />}
              <div className="flex flex-col md:flex-row items-stretch text-primary-500 my-6">
                <div className="text-left md:text-right py-3 px-7">
                  <div className="text-sm font-black text-standardgray-900 mb-2">Before Fiveoak</div>
                  <ReviewCount rating={card.beforefiveoakrating}
                    count={card.beforefiveoakcount}
                    logo={card.reviewsitelogo}
                    reviewsColor="#999999"
                    starOffColor="#cccccc"
                    size="sm"
                    color="#333333" />
                </div>

                <div className="hidden md:block bg-standardgray-100 w-0.5"></div>

                <div className="text-left py-3 px-7">
                  <div className="text-sm font-black text-standardgray-900 mb-2">With Fiveoak</div>
                  <ReviewCount rating={card.withfiveoakrating}
                    count={card.withfiveoakcount}
                    logo={card.reviewsitelogo}
                    reviewsColor="#999999"
                    starOffColor="#cccccc"
                    size="sm"
                    color="#333333" />
                </div>
              </div>
              {card.casestudyurl && <a href={card.casestudyurl}
                className="font-bold py-4 text-orange-500 hover:text-orange-600 flex flex-row items-center cursor-pointer">
                <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path style={{fill: 'currentColor'}} d="M7.42188 2.11719C7.03125 2.50781 7.07031 3.09375 7.42188 3.48438L12.1484 7.9375H0.9375C0.390625 7.9375 0 8.36719 0 8.875V10.125C0 10.6719 0.390625 11.0625 0.9375 11.0625H12.1484L7.42188 15.5547C7.07031 15.9453 7.07031 16.5312 7.42188 16.9219L8.28125 17.7812C8.67188 18.1328 9.25781 18.1328 9.60938 17.7812L17.2266 10.1641C17.5781 9.8125 17.5781 9.22656 17.2266 8.83594L9.60938 1.25781C9.25781 0.90625 8.67188 0.90625 8.28125 1.25781L7.42188 2.11719Z" />
                </svg>
                <span className="ml-2">View Case Study</span>
              </a>}
            </div>
          );
        })}
      </div>
    </section>
  )
}

export default LandingSuccessStories
