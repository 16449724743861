import { getImage, GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";
import Stars from "./stars";

// markup
const ReviewCount = ({ count, rating, color, logo, size, starOffColor, reviewsColor }) => {
  size = size || 'sm';
  starOffColor = starOffColor || '#ffffff';
  color = color || '#ffffff';
  const colorStyle = {
    color
  };
  reviewsColor = reviewsColor || color;
  const reviewsStyle = {
    color: reviewsColor
  };
  let logoImage;
  if (logo && logo.imageFile) {
    logoImage = getImage(logo.imageFile);
  }
  return (
    <div className="flex flex-row items-center" style={colorStyle}>
      <div className={`${size === 'lg' ? 'text-5xl sm:text-7xl mr-4' : 'text-5xl mr-2'} font-black`}>{count}</div>
      <div>
        <div className="flex flex-row items-center justify-start">
          {logoImage && <GatsbyImage image={logoImage}
            alt={logo.alternativeText}
            className={`w-auto ${size === 'lg' ? 'h-6 sm:h-8' : 'h-4'} mr-2`} />}
          {logo && !logoImage && <img src={logo.publicUrl}
            alt={logo.alternativeText}
            loading="lazy"
            className={`w-auto ${size === 'lg' ? 'h-6 sm:h-8' : 'h-4'} mr-2`} />}
          <span className={`${size === 'lg' ? 'text-xl sm:text-2xl' : 'text-sm'} font-black`} style={reviewsStyle}>Reviews</span>
        </div>
        <div className="flex flex-row items-center">
          <span className={`${size === 'lg' ? 'text-2xl sm:text-3xl' : 'text-lg'} font-black mr-1`}>{rating.toFixed(1)}</span>
          <Stars rating={rating}
            offColor={starOffColor}
            starClass={size === 'lg' ? 'w-6 sm:w-8' : 'w-4'}></Stars>
        </div>
      </div>
    </div>
  )
}

export default ReviewCount
