import * as React from "react"
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

export const query = graphql`
  fragment LandingStepslistContent on FiveoakCMS_ComponentLandingStepslist {
    __typename
    title
    subtitle
    stepslistitem {
      body
      stepname
      title
      image {
        url
        publicUrl
        provider
        alternativeText
        ext
        imageFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

// markup
const LandingStepsList = ({ content }) => {
  return (
    <section className="text-primary-500 text-lg max-w-3xl mx-auto py-12 px-5 flex flex-col items-center">
      {content.title && <div className="mb-4 text-orange-500 text-sm uppercase font-bold">
        {content.title}
      </div>}
      {content.subtitle && <div className="mb-7 text-primary-500 text-4xl text-center">
        <ReactMarkdown>{content.subtitle}</ReactMarkdown>
      </div>}
      {content.stepslistitem && content.stepslistitem.length && <ol className="md:bg-gradient-to-b from-gray-300 to-gray-300 md:bg-center md:bg-no-repeat" style={{backgroundSize: '2px 100%'}}>
        {content.stepslistitem.map((listItem, i) => {
          const image = getImage(listItem.image.imageFile);
          return (<li key={i} className="flex flex-col items-center md:flex-row py-14 first:pt-0">
            <div className={`pt-5 md:pt-0 md:flex-1 ${i%2===0 ? 'order-1 md:pr-7' : 'order-3 md:pl-7'}`}>
              {listItem.stepname && <div className="text-center md:text-left text-orange-500 text-sm">
                {listItem.stepname}
              </div>}
              {listItem.title && <div className="text-center md:text-left font-black text-2xl">
                {listItem.title}
              </div>}
              {listItem.body && <div className="text-center md:text-left text-primary-300">
                <ReactMarkdown>{listItem.body}</ReactMarkdown>
              </div>}
            </div>
            <div className="hidden md:order-2 md:flex md:flex-row md:items-center">
              <div className={`h-0.5 w-20 ${i%2===0 ? 'bg-transparent' : 'bg-gray-300'}`}></div>
              <div className="bg-primary-500 w-6 h-6 rounded-full border-white border-4"></div>
              <div className={`h-0.5 w-20 ${i%2===0 ? 'bg-gray-300' : 'bg-transparent'}`}></div>
            </div>
            <div className={`md:flex-1 ${i%2===0 ? 'md:order-3 md:pl-7' : 'md:order-1 md:pr-7'}`}>
              {listItem.image.imageFile && <GatsbyImage image={image}
                alt={listItem.image.alternativeText}
                className={`${i%2===0 ? '' : 'md:ml-auto'}`} />}
              {!listItem.image.imageFile && <img src={listItem.image.publicUrl}
                alt={listItem.image.alternativeText}
                loading="lazy"
                className={`${i%2===0 ? '' : 'md:ml-auto'}`} />}
            </div>
          </li>);
        })}
      </ol>}
    </section>
  )
}

export default LandingStepsList
